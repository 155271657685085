/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.bg-eye {
    background-image: url("../assets/eye-blue.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-eye-grey {
    background-image: url("../assets/eye-grey.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

:host {
    .custom-dialog {
        .mat-mdc-dialog-surface mdc-dialog__surface {
            padding: 0 !important;
        }
    }
}

.theme-default .light .mat-mdc-snack-bar-container, .theme-default.light .mat-mdc-snack-bar-container {
    &.error-snackbar {
        --mat-mdc-snack-bar-button-color: #333333 !important;
        --mdc-snackbar-container-color: #f44336 !important;
        --mdc-snackbar-supporting-text-color: #fff !important;
    }
}

.theme-default .light .mat-mdc-snack-bar-container, .theme-default.light .mat-mdc-snack-bar-container {
    &.success-snackbar {
        --mat-mdc-snack-bar-button-color: #333333 !important;
        --mdc-snackbar-container-color: #8bc34a !important;
        --mdc-snackbar-supporting-text-color: #fff !important;
    }
}

.theme-default .light .mat-mdc-snack-bar-container, .theme-default.light .mat-mdc-snack-bar-container {
    &.info-snackbar {
        --mat-mdc-snack-bar-button-color: #333333 !important;
        --mdc-snackbar-container-color: #2196f3 !important;
        --mdc-snackbar-supporting-text-color: #fff !important;
    }
}

.app-modal {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        padding: 0 !important;
    }
}

.fuse-alert-content {
    width: 100%;
}

.fuse-horizontal-navigation-wrapper {
    width: 100%;
    overflow: scroll;
}

fuse-horizontal-navigation .fuse-horizontal-navigation-wrapper fuse-horizontal-navigation-basic-item .fuse-horizontal-navigation-item-wrapper .fuse-horizontal-navigation-item .fuse-horizontal-navigation-item-icon, fuse-horizontal-navigation .fuse-horizontal-navigation-wrapper fuse-horizontal-navigation-branch-item .fuse-horizontal-navigation-item-wrapper .fuse-horizontal-navigation-item .fuse-horizontal-navigation-item-icon {
    margin-right: 4px !important;
}

fuse-horizontal-navigation .fuse-horizontal-navigation-wrapper fuse-horizontal-navigation-basic-item .fuse-horizontal-navigation-item-wrapper .fuse-horizontal-navigation-item, fuse-horizontal-navigation .fuse-horizontal-navigation-wrapper fuse-horizontal-navigation-branch-item .fuse-horizontal-navigation-item-wrapper .fuse-horizontal-navigation-item {
    padding: 0 8px !important;
}

fuse-horizontal-navigation .fuse-horizontal-navigation-wrapper {
    justify-content: space-between;
}

@media (max-width: 600px) {
    .mdc-dialog .mdc-dialog__content {
        padding: 0 !important;
    }
}
